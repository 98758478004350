(function ($) {

    const 
        block = $('.block-cover'),
        body = $('body')[0];

    block.each(function(i, ele) {
        
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            col = $this.find('.block__col'),
            img = $this.find('.block__img');

        // init parallax
        body.anim__parallax(img, ele);
        // all col animate up
        tl.add( body.anim__fadeUpSm(col) );
    });

})(jQuery);