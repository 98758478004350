(function ($) {

    const 
        block = $('.block-data'),
        body = $('body')[0];

    block.each(function(i, ele) {
        
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            // list block components
            row = $this.find('.block__row'),
            col = $this.find('.block__col'),
            tagline = $this.find('.block__tagline'),
            title = $this.find('.block__title'),
            summary = $this.find('.block__summary'),
            item = $this.find('.block__item'),
            graph = $this.find('.block__graph'),
            table = $this.find('.block__table');

            if($this.hasClass('title-summary-data-cols')) {
                tl.add( body.anim__fadeUpSm([title, summary]) )
                item.each(function(i, ele) {
                    var counter = $(ele).find('.counter');
                    tl.add( body.anim__fade(ele).duration(0.3), '-=0.5' )
                    tl.add( body.anim__counter(counter, 1), '-=0.5' )
                });
            }

            if($this.hasClass('data-list-cols')) {
                item.each(function(i, ele) {
                    var counter = $(ele).find('.counter');
                    tl.add( body.anim__fade(ele).duration(0.3), '-=0.5' )
                    tl.add( body.anim__counter(counter, 0, 0.9), '-=0.5' )
                });
            }
  
            if($this.hasClass('tagline-summary-bar-chart')) {
                tl.add( body.anim__fadeUpSm([tagline, summary]) );
                tl.add( body.anim__fadeLeftSm(graph) );
                item.each(function(i, ele) {
                    tl.add( body.anim__graphX(ele) );
                });
            };

            if($this.hasClass('title-table')) {
                tl.add( body.anim__fadeUpSm(title) );
                tl.add( body.anim__fadeLeftSm(table) );
            }

    });

})(jQuery);