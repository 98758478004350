(function ($) {
    const $wrapper = $('.masonry');

    $wrapper.masonry();
    $( window ).on('load', function() {
        $wrapper.masonry();
    });



})(jQuery);