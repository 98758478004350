(function ($) {

    const 
        block = $('.block-features'),
        body = $('body')[0];

    block.each(function(i, ele) {
        
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            // list block components
            row = $this.find('.block__row'),
            col = $this.find('.block__col'),
            title = $this.find('.block__title'),
            summary = $this.find('.block__summary'),
            img = $this.find('.block__img'),
            item = $this.find('.block__item');

        // fade in all columns
        if($this.hasClass('icon-title-summary-cols')) {
            tl.add( body.anim__fadeUpSm(item) )
        }

        if($this.hasClass('title-summary-img-feat-cols')) {
            tl.add( body.anim__fadeUpSm([title, summary]) )
            tl.add( body.anim__fadeLeftSm(img) )
            tl.add( body.anim__fadeUpSm(item) )
        }

        if($this.hasClass('title-summary-img-grid')) {
            tl.add( body.anim__fadeUpSm([title, summary]) )
            item.each(function(i, ele) {
                tl.add( body.anim__fadeUpSm(ele), '-=0.4')
            });    
        }

    });

})(jQuery);