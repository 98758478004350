(function ($) {

    const 
        block = $('.block-company'),
        body = $('body')[0];

    block.each(function(i, ele) {
        
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            // list block components
            row = $this.find('.block__row'),
            col = $this.find('.block__col'),
            item = $this.find('.block__item'),
            carousel = $this.find('.block__carousel'),
            title = $this.find('.block__title'),
            summary = $this.find('.block__summary');

        if($this.hasClass('title-summary-cta-masonry')) {
            tl.add( body.anim__fadeUpSm(item) );
            tl.add( body.anim__fade(col[1]) );
        }

        if($this.hasClass('carousel-title-summary')) {
            tl.add( body.anim__fadeLeftSm(carousel) );
            tl.add( body.anim__fadeLeftSm([title, summary]) );
            tl.add( body.anim__fade(col[2]) );
        }
            
        if($this.hasClass('title-summary-avatar-grid')) {
            tl.add( body.anim__fadeUpSm([title, summary]) );
            item.each(function(i, ele) {
                tl.add( body.anim__fade(ele), '-=0.3');
            });
        }
        if($this.hasClass('avatar-card-social')) {
            item.each(function(i, ele) {
                tl.add( body.anim__fadeUpSm(ele), '-=0.3');
            });
        }
    });

})(jQuery);

