// plugins
import '../plugins/slick/slick.min.js';

// design system
import './demo/_radioSwitchClass.js';

import './components/_helpers.js';

import './components/_carousel.js';
import './components/_header.js';
import './components/_masonry.js';
// import './components/_nav.js';
import './components/_nav.v2.js';
import './components/_tabs.js';
import './components/_video.js';

import './animation/scroll/_pre.js';
import './animation/scroll/_hero.js';
import './animation/scroll/_intro.js';
import './animation/scroll/_detail.js';
import './animation/scroll/_data.js';
import './animation/scroll/_features.js';
import './animation/scroll/_img.js';
import './animation/scroll/_cover.js';
import './animation/scroll/_carousel.js';
import './animation/scroll/_video.js';
import './animation/scroll/_company.js';
import './animation/scroll/_news.js';
import './animation/scroll/_contact.js';
import './animation/scroll/_cta.js';
