(function ($) {

    const 
        block = $('.block-intro'),
        body = $('body')[0];

    block.each(function(i, ele) {
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            // list block components
            tagline = $this.find('.block__tagline'),
            title = $this.find('.block__title'),
            summary = $this.find('.block__summary'),
            cta = $this.find('.block__cta'),
            item = $this.find('.block__item');

            if($this.hasClass('tagline-title-summary')) {
                tl.add( body.anim__fadeUpSm([tagline, title, summary]) )
            }
            if($this.hasClass('summary-cta')) {
                tl.add( body.anim__fadeUpSm([summary, cta]) )
            }
            if($this.hasClass('title-summary-2col')) {
                tl.add( body.anim__fadeRightSm(title) )
                tl.add( body.anim__fadeLeftSm(summary) )
            }

            if($this.hasClass('title-summary-grid')) {
                tl.add( body.anim__fadeUpSm(item) )
            }
    });

})(jQuery);