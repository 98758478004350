(function ($) {
    const video = $('.video');

    function removeVideoControls(video) {
        video.each(function(){
            if (this.hasAttribute('controls')) {
                this.removeAttribute("controls")   
            }
        }); 
    }

    function addVideoControls(video) {
        video.each(function(){
            if (!this.hasAttribute('controls')) {
                this.setAttribute('controls','controls');      
            }
        }); 
    }


    video.each(function(i){
        const 
            $wrapper = $(this),
            video = $wrapper.find('video');

        if ($wrapper.hasClass('video--play')) {
            removeVideoControls(video);
        }
    });

    $('.video.video--play').on('click', '.video__overlay', function(e){
        const 
            overlay = $(this),
            $wrapper = overlay.closest('.video'),
            video = $wrapper.find('video'),
            tl = gsap.timeline({});

            tl.to(overlay, {opacity: 0});
            tl.add( function() { overlay.remove() })
            tl.add( function() { $wrapper.removeClass('video--play') });
            tl.add( function() { addVideoControls(video)  });
            tl.add( function() { video[0].play()  });


            // hide overlay
            // gsap.to(overlay, {opacity: 0});

    });




})(jQuery);
