(function ($) {
    const 
        carousel = $('.carousel'),
        carouselBtns = $('.carousel__btns'),
        carouselNextLink = $('.carousel__next'),
        carouselPrevBtn = $('.carousel__prevBtn'),
        carouselNextBtn = $('.carousel__nextBtn');

    carousel
    .on('init', function(event, slick){
        let section = slick.$slider.closest('section'),
            progress = section.find('.carousel__progress'),
            btns = section.find('.carousel__btns'),
            nextLink = section.find('.carousel__next'),
            prevBtn = section.find('.carousel__prevBtn'),
            nextBtn = section.find('.carousel__nextBtn');

        if (progress.length) {
            progress.append(
                '<span class="progress__current">' + ('0' + (slick.currentSlide+1)).slice(-2) + '</span>'+
                '/'+
                '<span class="progress__count">' + ('0' + slick.slideCount).slice(-2)  + '</span>'
            );
        }
        if (btns.length) {
            btns.append(
                '<a href="#" class="icon icon--arrow-left prev"></a>'+
                '<a href="#" class="icon icon--arrow-right next"></a>'
            );
        }
        if(nextLink.length) {
            nextLink.append('<a href="#" class="text-link next">Next</a>');
        }

        if(prevBtn.length) {
            prevBtn.append('<a href="#" class="icon icon--arrow-left prev"></a>');
        }
        if(nextBtn.length) {
            nextBtn.append('<a href="#" class="icon icon--arrow-right next"></a>');
        }
    })
    .each(function() {
        let $this = $(this),
            options = {};
        if($this.hasClass('carousel--imgshow1') || $this.hasClass('carousel--imgshow2') || $this.hasClass('carousel--imgshow3')) {
            options.arrows = 0;
        }
        if($this.hasClass('carousel--imgshow3') ) {
            options.slidesToShow = (window.innerWidth > 767 ? 3 : 1);
        }
        if($this.hasClass('carousel--imgshow2') ) {
            options.slidesToShow = (window.innerWidth > 767 ? 2 : 1);
        }
        if($this.hasClass('carousel--varwidth')) {
            options.variableWidth = true;
            options.arrows = 0;
        }
        if($this.hasClass('carousel--flex')) {
            options.dots= true;
        }
        $this.slick(options);
    })
    .on('afterChange', function(event, slick, currentSlide){
        let section = slick.$slider.closest('section'),
            progress = section.find('.carousel__progress');
        if (progress.length) {
            progress.find('.progress__current').text( ('0' + (slick.currentSlide+1)).slice(-2) )
        }
    });
    

    carouselBtns.on('click', 'a', function(e){
        e.preventDefault();
        let relCarousel = $(this).closest('section').find('.carousel'),
            prev = this.className.indexOf('prev') !== -1;    

        relCarousel.slick( (prev ? 'slickPrev' : 'slickNext') );
    })

    carouselNextLink.on('click', 'a', function(e){
        e.preventDefault();
        let relCarousel = $(this).closest('section').find('.carousel');
        relCarousel.slick('slickNext');
    })

    carouselPrevBtn.on('click', 'a', function(e){
        e.preventDefault();
        let relCarousel = $(this).closest('section').find('.carousel');
        relCarousel.slick('slickPrev');
    });

    carouselNextBtn.on('click', 'a', function(e){
        e.preventDefault();
        let relCarousel = $(this).closest('section').find('.carousel');
        relCarousel.slick('slickNext');
    });




})(jQuery);