(function ($) {

    const 
        block = $('.block-contact'),
        body = $('body')[0];

    block.each(function(i, ele) {
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            row = $this.find('.block__row'),
            col = $this.find('.block__col'),
            tagline = $this.find('.block__tagline'),
            title = $this.find('.block__title'),
            summary = $this.find('.block__summary'),
            cta = $this.find('.block__cta'),
            img = $this.find('.block__img'),
            carousel = $this.find('.block__carousel'),
            card = $this.find('.block__card'),
            item = $this.find('.block__item'),
            form = $this.find('.block__form'),
            map = $this.find('.block__map');

            if($this.hasClass('map-title-summary-cta')) {
                tl.add( body.anim__fadeRightSm(col) )
            }

            if($this.hasClass('map-title-summary-form')) {
                tl.add( body.anim__fadeUpSm(col) )
            }

            if($this.hasClass('title-summary-form')) {
                tl.add( body.anim__fadeUpSm(col) )
                tl.add( body.anim__fadeUpSm(form) )
            }

            if($this.hasClass('email-tel-address-social')) {
                tl.add( body.anim__fadeLeftSm(col) )
            }
    });



    })(jQuery);