const header = document.querySelector('.header');
let headerVisible = true;

ScrollTrigger.create({
    start: 'top',
    end: 'bottom bottom ',
    onUpdate: self => {
        if (self.progress == 0) {
            gsap.to(header, { marginTop: 0});
            headerVisible = true;
        } else if (self.progress == 1 || !headerVisible && self.direction == -1) {
            gsap.to(header, { marginTop: 0});
            headerVisible = true;
        } else if ( headerVisible && window.pageYOffset > 80 && self.direction == 1 ) {
            gsap.to(header, { marginTop: -(header.offsetHeight + 10)});
            headerVisible = false;
        }
    },
    // show footer when user hits the bottom
    onLeave: function() {
        gsap.to(header, { marginTop: 0});
        headerVisible = true;
    }
});