(function ($) {

    const 
        block = $('.block-carousel'),
        body = $('body')[0];

    block.each(function(i, ele) {

        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            // list block components
            row = $this.find('.block__row'),
            col = $this.find('.block__col'),
            cta = $this.find('.block__cta'),
            carousel = $this.find('.block__carousel'),
            card = $this.find('.card');

        if($this.hasClass('title-img-text')) {
            tl.add( body.anim__fadeRightSm(col) );
        }

        if($this.hasClass('tagline-title-summary-carousel-cta')) {
            tl.add( body.anim__fadeUpSm(col[0]) );
            tl.add( body.anim__fadeUpSm(carousel) );
            tl.add( body.anim__fade(cta) );
        }

        if($this.hasClass('img-card-tagline-title-summary-social')) {
            tl.add( body.anim__fadeLeftSm(carousel) );
            tl.add( body.anim__fadeRightSm(card) );
            tl.add( body.anim__fade([col[0],col[1]]) );
        }

        if($this.hasClass('testimonial-carousel')) {
            tl.add( body.anim__fadeLeftSm(row) );
        }
        
    });

})(jQuery);