(function ($) {

    const 
        block = $('.block-detail'),
        body = $('body')[0];

    block.each(function(i, ele) {
        
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            // list block components
            row = $this.find('.block__row'),
            col = $this.find('.block__col');

            // fade in all columns
            tl.add( body.anim__fade(col) )

    });

})(jQuery);