(function ($) {

    const 
        block = $('.block-news'),
        body = $('body')[0];

    block.each(function(i, ele) {
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            row = $this.find('.block__row'),
            col = $this.find('.block__col'),
            tagline = $this.find('.block__tagline'),
            title = $this.find('.block__title'),
            summary = $this.find('.block__summary'),
            cta = $this.find('.block__cta'),
            img = $this.find('.block__img'),
            carousel = $this.find('.block__carousel'),
            card = $this.find('.block__card'),
            item = $this.find('.block__item');

            if($this.hasClass('tagline-title-tabs-cta')) {
                tl.add( body.anim__fadeUpSm(col[0], col[1]) );
                tl.add( body.anim__fade(item) );
                tl.add( body.anim__fade(cta) );
            }

            if($this.hasClass('tagline-title-cards')) {
                tl.add( body.anim__fadeUpSm(col[0]) );
                tl.add( body.anim__fadeUpSm(item));
            }
    });



    })(jQuery);