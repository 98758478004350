(function ($) {

    const 
        block = $('.block-video'),
        body = $('body')[0];

    block.each(function(i, ele) {    

        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            // list block components
            row = $this.find('.block__row'),
            col = $this.find('.block__col'),
            video = $this.find('.block__video');

            if($this.hasClass('title-cta-video-text')) {
                tl.add( body.anim__fadeUpSm(col) );
            }
            
            if($this.hasClass('summary-features-video-col')) {
                tl.add( body.anim__fadeLeftSm(video) );
                tl.add( body.anim__fadeRightSm(col[1]) );
            }
    });

})(jQuery);