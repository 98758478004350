(function ($) {

    const 
        block = $('.block-cta'),
        body = $('body')[0];

    block.each(function(i, ele) {
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            // list block components
            col = $this.find('.block__col');

        tl.add( body.anim__fadeUpSm(col) );

    });

})(jQuery);