gsap.registerPlugin(ScrollTrigger);

// add comma over 1000
function toCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

(function ($) {
    // store scroll function in body
    $('body')[0].gsapScrollFunct = function(ele){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: ele,
                start: -window.innerHeight / 4 + ' center',
            }
        });
        tl.to(ele, { duration: 0.2, visibility: 'visible', opacity: 1});
        gsap.set('.hero', {visibility: 'hidden' ,opacity: 0});
        
        return tl;
    }

    // ==================
    // fade
    $('body')[0].anim__fade = function(ele){
        let tl = gsap.timeline();

        gsap.set(ele, {opacity: 0,});
        tl.to(ele, { opacity: 1, stagger: 0.25 });

        return tl;
    }
    // ==================

    // ==================
    // fade up by 50
    $('body')[0].anim__fadeUpSm = function(ele){
        let tl = gsap.timeline();

        gsap.set(ele, {y: 50, opacity: 0 });
        tl.to(ele, { y: 0, opacity: 1, stagger: 0.25});

        return tl;
    }
    // ==================

    // ==================
    // fade right by 50
    $('body')[0].anim__fadeRightSm = function(ele){
        let tl = gsap.timeline();

        gsap.set(ele, {x: -50, opacity: 0,});
        tl.to(ele, { x: 0, opacity: 1, stagger: 0.25 });

        return tl;
    }
    // ==================

    // ==================
    // fade left by 50
    $('body')[0].anim__fadeLeftSm = function(ele){
        let tl = gsap.timeline();

        gsap.set(ele, {x: 50, opacity: 0,});
        tl.to(ele, { x: 0, opacity: 1, stagger: 0.25 });

        return tl;
    }
    // ==================

    // ==================
    // numeric counter
    $('body')[0].anim__counter = function(ele, decimal=0, duration=1){
        let tl = gsap.timeline(),
            zero = {val:0};

        gsap.set(ele, {text: '0'});

        tl.to(zero, { 
            val: ele.data("number"), 
            duration: duration, 
            onUpdate: function() { 
                ele.text(toCommas(zero.val.toFixed(decimal)));
            }
        });

        return tl;
    }
    // ==================  
    
    // ==================
    // numeric counter
    $('body')[0].anim__graphX = function(ele, decimal=0, duration=1){
        let tl = gsap.timeline();

        gsap.set(ele, {width: 0});
        tl.to(ele, { width: $(ele).data('value') + '%' });

        return tl;
    }
    // ==================

    // ==================
    // parallax
    $('body')[0].anim__parallax = function(ele, section){
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: section,
                start: 'top bottom',
                end: 'bottom top',
                scrub: true,
            }
        });
        
        tl.to(ele, {y: -150, ease: "none"}, 0);

        return tl;
    };

    window.addEventListener('load', (event) => {
        ScrollTrigger.refresh();
    });
    

})(jQuery);