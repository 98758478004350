(function ($) {
    const 
        $nav = $('.nav'),
        $input = $('#menu'), 
        tl = gsap.timeline({
            paused: true,
            onStart: function() {
                $('html').addClass('nav-acitve');
            },
            onReverseComplete: function() {
                $('html').removeClass('nav-acitve');
            },
        });

        tl.to('html', 0,{ overflow: 'hidden' })
        tl.add( animation($nav) );

    // add animation to element
    if($input.length) $input[0].animation = tl;

    // event handlers
    $input.on('change',function(e){
        let checked = $(this).prop('checked');
        toggle(checked);
    })

    $nav.on('click', 'a', function(){
        toggle(false);
    });
    
    $(document).on('ready', function() {
        toggle(false);
    });

    // functions
    function toggle(open = true) {
        if($input.length)
        $input.prop('checked', open);
        open ? $input[0].animation.play() : $input[0].animation.reverse();
    }

    function animation($ele) {
        const 
            tl = gsap.timeline({}),
            bg = $nav.find('.nav__close-bg'),
            inner = $nav.find('.nav__inner')

            gsap.set($nav, { display: 'none'});
            gsap.set(bg, { opacity: 0});
            gsap.set(inner, { xPercent: -100});

            tl.set($nav, { display: 'block'})
            tl.to(bg, { opacity: 1 }, 0)
            tl.to(inner, { xPercent: 0, duration: 0.2}, 0)

            return tl;
    }




})(jQuery);