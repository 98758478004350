(function ($) {

    const 
        block = $('.hero'),
        body = $('body')[0];

    block.each(function(i, ele) {
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            // list block components
            tagline = $this.find('.hero__tagline'),
            title = $this.find('.hero__title'),
            summary = $this.find('.hero__summary'),
            form = $this.find('.hero__form'),
            video = $this.find('.hero__video'),
            cta = $this.find('.hero__cta'),
            scroll = $this.find('.hero__scroll'),
            img = $this.find('.hero__img'),
            social = $this.find('.hero__social');

        if($this.hasClass('hero__splitX')) {
            tl.add( body.anim__fadeUpSm([title, cta, img]) )
            tl.add( body.anim__fadeRightSm(scroll) )
        }

        if($this.hasClass('hero__splitY')) {
            tl.add( body.anim__fadeUpSm([title, summary, form, cta]) )
            tl.add( body.anim__fadeLeftSm(img) )
            tl.add( body.anim__fadeUpSm(video) )
        }

        if($this.hasClass('hero__cover')) {
            tl.add( body.anim__fade(img) )
            tl.add( body.anim__fadeRightSm([tagline, title, summary]) )
            tl.add( body.anim__fadeUpSm(form) )
            tl.add( body.anim__fadeLeftSm(social) )
        }
    });

})(jQuery);