(function ($) {

    const 
        block = $('.block-img'),
        body = $('body')[0];

    block.each(function(i, ele) {
        
        let $this = $(ele),
            tl = body.gsapScrollFunct(ele),
            // list block components
            row = $this.find('.block__row'),
            col = $this.find('.block__col'),
            title = $this.find('.block__title'),
            summary = $this.find('.block__summary'),
            cta = $this.find('.block__cta'),
            item = $this.find('.block__item'),
            img = $this.find('.block__img');
            // fade in all columns
            // tl.add( body.anim__fade(col) )

            if($this.hasClass('img-repeater')) {
                tl.add( body.anim__fadeLeftSm(item) )
            }

            if($this.hasClass('img-summary-cta-repeater')) {
                item.each(function(i, ele) {
                    tl.add( body.anim__fadeLeftSm(img[i]), '-=0.4')
                    tl.add( body.anim__fadeUpSm([title[i], summary[i], cta[i]]), '-=0.4')
                });
            }

            if($this.hasClass('img-title-summary-features-cta') || $this.hasClass('icon-features-img')) {
                tl.add( body.anim__fadeRightSm(col[1]) );
                tl.add( body.anim__fadeLeftSm(col[0]) );
            }
            if($this.hasClass('img-aside-title-summary-cta-repeater')) {
                item.each(function(i, ele) {
                    tl.add( body.anim__fade(ele));
                });
            }
            if($this.hasClass('title-img-carousel')) {
                tl.add( body.anim__fadeLeftSm(row));
            }

    });

})(jQuery);