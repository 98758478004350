(function ($) {
    const group = $('.js-radioSwitchClass');

    group.on('change', 'input', function(){
        let target = $('body'),
            name = $(this).attr('name'),
            id = $(this).attr('id'),
            classArr = $('[name="'+name+'"]').map(function(){
                return $(this).attr('id')
            });

        $.each(classArr, function( index, value ) {
            target.removeClass(value);
            if(value == id) {
                $(target).addClass(value);
            }
        });
    });

})(jQuery);