(function ($) {
    var serviceTab = $('.tabs');

    serviceTab.each(function() {
        // ===========================
        // set vars
        // ===========================
        var $this = $(this);
        var nav = $this.find('.tabs__nav');
        var navItem = nav.children();
        var content = $this.find('.tabs__content');
        var contentItem = content.children();
    
        var hasItems = contentItem.length && navItem.length;
        var tabAnimating = false;

        // ===========================
        // functions
        // ===========================
        function tabsInit() {
            // add classes 
            $this.addClass('init');
            $([navItem[0], contentItem[0]]).addClass('active');

            // set animation stuff
            gsap.set(contentItem, { css: { opacity:0, visibility: 'hidden'}});
            gsap.set(contentItem[0], { css: { opacity:1, visibility: 'visible'}});
            // gsap.set(content , { height: $(contentItem[0]).outerHeight() });

            tabsSet(0)

            // adjusts content height on resize
            $( window ).resize(function() {
                gsap.set(content , { height: $(contentItem[0]).outerHeight() });
            });
        }
        

        function tabsSet(i) {
            var prev = $(contentItem[i]).siblings('.active');
            var animOut = (prev.length ? prev[0].animation : false)
            var animIn = (contentItem[i] !== undefined ? contentItem[i].animation : false)

            if(animOut) animOut.seek('In').tweenTo('Out');
            if(animIn) animIn.seek('Ready').tweenTo('In');

            $([contentItem[i], navItem[i]]).addClass('active').siblings().removeClass('active');
        }

        // ===========================
        // animation
        // ===========================

        contentItem.each(function(i, ele) {
            var tl = gsap.timeline({paused: true, reversed: true});

            tl.to(ele, { duration: 0, opacity:0, visibility: 'visible'}, 0)
            tl.addLabel('Ready')
            tl.to(ele, { opacity: 1}, 'Ready')
            tl.to(content, { duration: 0, height: $(ele).outerHeight() }, 'Ready')
            tl.addLabel('In')
            tl.to(ele, { opacity: 0}, 'In')
            tl.to(ele, { duration: 0, visibility: 'visible'}, 'In')
            tl.addLabel('Out')

            this.animation = tl;
        });

        // ===========================
        // click
        // ===========================
        navItem.on('click', 'a', function(e) {
            e.preventDefault();
            var $this = $(this).parent();

            if(!$this.hasClass('active') && !tabAnimating) {
                var i = $this.prevAll().length;
                tabsSet(i)

                // timeout to stop fuckers trying to break
                tabAnimating = true;
                setTimeout(function(){ tabAnimating = false; }, 600);
            }
        });

        // ===========================
        // Init 
        // ===========================
        tabsInit()

    });
    
})(jQuery);